<template>
  <div class="select_box">
    <span class="select_name">{{option.name||""}}</span>
    <el-select v-model="option.value" :placeholder="placeholder" @change="select_event">
      <el-option
        v-for="item in option.list"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    name:'selectInput',
    props:{
      placeholder:{
        type:String,
        default:'请选择'
      },
      option:{
        type:Object,
      }
    },
    data() {
      return{}
    },
    methods:{
      select_event(){
        this.$emit("select_event")
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
.select_box{
  display: flex;
  align-items: center;
}
.select_name{
  margin-right: 8px
}
/deep/.el-input__icon{
  line-height: 32px !important
}
</style>

