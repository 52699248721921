<template>
    <div class="works_income">
        <div class="works_income_top">
            <div class="income_msg">
                <div class="msg_item">
                    <span>累计收入/元</span>
                    <span>{{ data_list.total_in_money || 0 }}</span>
                </div>
                <div class="msg_item">
                    <span>账户余额/元</span>
                    <span>{{ data_list.user_money || '0.00' }}</span>
                </div>
            </div>
            <div class="btn_arr">
                <el-button plain @click="go_record(1)" class="btn_ispain">提现记录</el-button>
                <el-button type="primary" @click="go_record(2)">提 现</el-button>
            </div>
        </div>
        <div class="tab_box">
            <div class="seach_arr">
                <selectInput :option="select_one"></selectInput>
                <selectInput :option="select_type" v-if="income_type" @select_event="get_data()"
                             :placeholder="'请选择类型'"></selectInput>
                <selectInput :option="select_two" v-if="book_type" @select_event="get_data()"></selectInput>
                <div>
                    <el-date-picker
                        v-model="data_year"
                        type="year"
                        :clearable="false"
                        :picker-options="pickerOptions0"
                        @change="get_data()"
                        placeholder="选择年">
                    </el-date-picker>
                </div>
                <selectInput :option="select_day" :placeholder="'请选择月'" @select_event="get_data()"></selectInput>
            </div>
            <span class="income_title">收入分析</span>
            <!-- <cBar class="columnar" :data="liftCost" :colors="liftCost.bgColor"></cBar> -->
            <div id="round" style="width:100%;height:300px"></div>
        </div>
        <div class="">
            <span>收入明细</span>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="type" label="收益类型" align="center">
                    <template slot-scope="scope">
                        <div>
                            {{ {1: '按章付费', 2: '千字分成', 3: '整本买断稿费', 4: '师傅提成', 5: '千字稿费', 6: '道具打赏'}[scope.row.type] }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="作品名" align="center"></el-table-column>
                <el-table-column prop="title" label="章节" align="center"></el-table-column>
                <el-table-column prop="total_money" label="总收益（书币）" align="center"></el-table-column>
                <el-table-column prop="de_dec" label="扣减项" align="center"></el-table-column>
                <el-table-column prop="rel_money" label="实收益" align="center"></el-table-column>
                <el-table-column prop="cre_time" label="时间" align="center"></el-table-column>
            </el-table>
            <c-page :callBack="get_data" :page="page"></c-page>
        </div>
    </div>
</template>
<script>
import echarts from 'echarts'
import cBar from "@/components/echarts/c-bar"
import selectInput from "@/components/input/select-input"
import {author_record_info, author_books} from "@/https/mine_center.js";
// import getLiftCostApi from ""
export default {
    name: 'worksIncome',
    components: {
        cBar,
        selectInput
    },
    data() {
        return {
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6
                }
            },
            select_one: {
                name: '查看方式',
                value: 3,
                list: [{value: 3, label: '按月份'}, {value: 2, label: '按作品'}, {value: 1, label: '按类型'}]
            },
            select_two: {
                value: '',
                list: []
            },
            data_year: new Date(),
            data_list: '',
            select_three: {
                value: '',
                list: []
            },
            select_type: {
                value: '',
                list: [{value: 1, label: '稿费'}, {value: 2, label: '师傅提成'}, {value: 3, label: '用户打赏'}]
            },
            select_day: {
                value: new Date().getMonth() + 1,
                list: [
                    {value: 1, label: '1'},
                    {value: 2, label: '2'},
                    {value: 3, label: '3'},
                    {value: 4, label: '4'},
                    {value: 5, label: '5'},
                    {value: 6, label: '6'},
                    {value: 7, label: '7'},
                    {value: 8, label: '8'},
                    {value: 9, label: '9'},
                    {value: 10, label: '10'},
                    {value: 11, label: '11'},
                    {value: 12, label: '12'}
                ]
            },
            tableData: [],
            income_type: false,
            book_type: false,
            time: [],
            count: [],
            page: {page: 1, limit: 10, total: 0},
        }
    },
    watch: {
        'select_one.value': {
            handler(n, v) {
                n === 1 ? this.income_type = true : this.income_type = false
                n === 2 ? this.book_type = true : this.book_type = false
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.avoid_books()
    },
    mounted() {
        this.$nextTick(() => {
            this.avoid_record()
        })
    },
    methods: {
        avoid_books() {
            author_books().then((res) => {
                if (res.code === 0) {
                    this.select_two.list = []
                    if (res.data && res.data.length) {
                        res.data.forEach(item => {
                            let obj = {}
                            obj.value = item.id
                            obj.label = item.name
                            this.select_two.list.push(obj)
                        });
                    }
                }
            })
        },
        loadData() {

        },
        go_record(num) {
            if (num === 1) {
                this.$router.push({
                    path: '/withdrawal'
                })
            }
            if (num === 2) {
                this.$router.push({
                    path: '/go_withdrawal'
                })
            }
        },
        init_echart() {
            let myChart = echarts.init(document.getElementById('round'));
            let option = {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '5%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        name: "时间",
                        data: this.time,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        name: "鲸币",
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '金额',
                        type: 'bar',
                        barWidth: '60%',
                        data: this.count
                    }
                ],
            };
            myChart.setOption(option)
        },
        avoid_record(params) {
            let props = ''
            if (!params) {
                props = {
                    ...this.page
                }
            }
            author_record_info(params ? params : props).then((res) => {
                if (res.code === 0) {
                    let data = res.data
                    this.page.total = res.data.history.total
                    this.data_list = data
                    this.tableData = data.history.data
                    this.time = []
                    this.count = []
                    if (data.list_sum && data.list_sum.length) {
                        data.list_sum.forEach(item => {
                            this.time.push(item.time)
                            this.count.push(item.count)
                        })
                    }
                    this.init_echart()
                } else {
                    this.$message.warning(res.msg)
                }
            })

        },
        get_data() {
            let params = {
                year: new Date(this.data_year).getFullYear(),
                month: this.select_day.value || '',
                type: this.select_one.value || '',
                ...this.page
            }
            if (params.type === 2) {
                params.books_id = this.select_two.value || ''
            }
            if (params.type === 1) {
                params.charge_type = this.select_type.value || ''
            }
            if (this.select_day.value) {
                this.avoid_record(params)
            } else {
                this.avoid_record(params)
                this.$message.warning("请选择年")
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../../utils/tool.scss";
// @include flex-center();
.works_income_top {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    @include flex-row-between();

    .income_msg {
        @include flex-row();

        .msg_item {
            margin-right: 30px;

            span:nth-child(1) {
                margin-right: 15px
            }

            span:nth-child(2) {
                font-weight: bold
            }
        }
    }

    .btn_arr {
        // width: 200px;
        /deep/ .el-button {
            padding: 8px 15px;
        }
    }
}

.tab_box {
    .seach_arr {
        @include flex-row();
        padding: 15px
    }

    .income_title {
        font-size: 14px
    }

    .columnar {
        width: 100%;
        height: 420px;
    }
}

.btn_ispain:hover {
    color: #409EFF !important
}

/deep/ .el-date-editor--year {
    margin-left: 10px;
    width: 165px;

    /deep/ .el-input__icon {
        line-height: 32px !important
    }
}
</style>
