<template>
    <div ref="chart" class="chart-bar"></div>
</template>

<script>
    import echarts from 'echarts'
    import {
        on,
        off
    } from '@/utils/event.js'

    export default {
        name: 'm-barLine',
        props: {
            data: [Object],
            colors: {
                type: [Array],
                default: ['#0066ff', '#2cb5fc', '#77ddaa']
            }
        },
        data() {
            return {
                chart: null,
                options: {
                    color: this.colors,
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        left: '6%',
                        right: '10%',
                        bottom: 50,
                        containLabel: true
                    },
                    legend: {
                        data: [],
                        bottom: 10,
                        itemWidth: 10, // 改变圆圈大小
                        itemHeight: 10, // 改变圆圈大小
                        textStyle: {
                            fontSize: 14, // 字体大小
                            color: '#ffffff' // 字体颜色
                        }
                    },
                    xAxis: [{
                        name: '时间',
                        nameTextStyle: {
                            color: '#fff'
                        },
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#fff'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#294a81'
                            }
                        },
                        data: []
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '单位：次',
                        position: 'right',
                        axisLine: {
                            lineStyle: {
                                color: this.colors[0]
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: '#294a81'
                            }
                        },
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                        {},
                        {
                            type: 'value',
                            name: '单位：元',
                            position: 'left',
                            axisLine: {
                                lineStyle: {
                                    color: this.colors[1]
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#294a81'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                        }
                    ],
                    series: []
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart()
            })
        },
        watch: {
            data: { // 深度监听，可监听到对象、数组的变化
                handler(newV, oldV) {
                    this.$nextTick(() => {
                        this.initChart()
                    })
                },
                deep: true
            }
        },
        methods: {
            resize() {
                this.chart.resize()
            },
            initChart() {
                this.chart = echarts.init(this.$refs.chart)
                // this.chart.showLoading({
                //   text: '加载中...',
                //   color: '#fff',
                //   textColor: '#fff',
                //   maskColor: 'transparent',
                //   zlevel: 0
                // })
                // 处理数据
                let yData = this.data.yData
                let legendData = []
                let series = []
                for (let i in yData) {
                    let obj = {
                        type: 'bar',
                        barMaxWidth: 20,
                        // type: 'line',
                        // yAxisIndex: 2,
                        name: yData[i].name,
                        data: yData[i].value
                    }
                    // 第二个是折线图
                    if (i === 1) {
                        obj = {
                            ...obj,
                            type: 'line',
                            yAxisIndex: 2
                        }
                    }
                    // 数据
                    series.push(obj)
                    // legend
                    legendData.push(yData[i].name)
                }
                this.options.legend.data = legendData
                this.options.xAxis[0].data = this.data.xData
                this.options.xAxis[0].name = this.data.xName
                this.options.series = series
                setTimeout(() => {
                    // setOption前隐藏loading事件
                    // this.chart.hideLoading()
                    this.chart.setOption(this.options)
                }, 500)
                on(window, 'resize', this.resize)
            }
        },
        beforeDestroy() {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style lang="less" scope>

</style>
